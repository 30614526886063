import React from 'react'
import Helmet from 'react-helmet'
import ConditionalLayout from '../components/ConditionalLayout'
import ServiceContainer from '../components/ServiceContainer'
import PageTitle from '../components/PageTitle'
import SEO from '../components/SEO'
import config from '../utils/siteConfig'

import { LargeP, FlexList, FlexItem } from '../elements'
import BecomeACarrier from '../components/BecomeACarrier'

const AssuredLoadsPage = ({ data }) => {
  const postNode = {
    title: `Assured Loads - ${config.siteTitle}`,
  }

  return (
    <ConditionalLayout>
      <Helmet>
        <title>{`Assured Loads & Great Rates - ${config.siteTitle}`}</title>
      </Helmet>
      <SEO postNode={postNode} pagePath="assured-loads" customTitle />

      <ServiceContainer>
        <PageTitle>Assured Loads & Great Rates</PageTitle>
        <LargeP>
          We understand that your business is more than just pickup and
          delivery. We'll find loads that meet your personal and professional
          goals.
        </LargeP>
        <p>
          Accommodating for freight in the lanes that make sense is a priority.
          Drivers are the backbone of a nation and as an asset-based company, we
          understand the need for work-life balance. Our seasoned dispatch team
          provides personalized attention to the specific needs of our carriers.
          We have an everyday commitment to building and maintaining strong
          relationships with our partnered carriers and customers. We make it
          easy for you to find loads, post trucks and move your business
          forward.
        </p>

        <BecomeACarrier />

        <h4>Benefits at a Glance</h4>
        <FlexList>
          <FlexItem>Fuel Discount Passthrough</FlexItem>
          <FlexItem>Run Our Trailers – Drop and Hook</FlexItem>
          <FlexItem>Huge Repair and Tire discounts</FlexItem>
          <FlexItem>Route Optimization</FlexItem>
          <FlexItem>Business Mentoring</FlexItem>
          <FlexItem>Clean No Touch Freight</FlexItem>
          <FlexItem>Quick Pay</FlexItem>
          <FlexItem>Advanced Technology</FlexItem>
        </FlexList>
      </ServiceContainer>
    </ConditionalLayout>
  )
}

export default AssuredLoadsPage
